<template>
<div class="deliver-record" :style="{'padding-bottom':isEdit?'96px':'16px'}">
  <BasicInfo :carInfo="detail"/>
  <div class="card" style="margin-top: 10px;">
    <div class="common-title">{{$t('资料上传')}}</div>
    <div class="card-form">
      <van-form ref="form" labelWidth="8.2em" class="record-form" inputAlign="left" @submit.native.prevent>
        <van-field :label="$t('交车确认函')" readonly required>
          <template #input>
            <van-uploader
              class="upload"
              accept="image/*"
              :preview-options="{closeable: true}"
              :before-read="beforeRead"
              :deletable="isEdit"
              :after-read="(file) => afterRead(file)"
              :file-list="fileList"
              :before-delete="(file) => beforeDelete(file)"
              :max-count="isEdit?3:fileList.length"
              multiple/>
            </template>
        </van-field>
        <div v-if="isEdit" class="common-footer">
          <van-button class="btn back" native-type="button" @click.stop="goBack">{{$t('返回')}}</van-button>
          <van-button class="btn submit" type="submit" @click="onSubmit">{{$t('提交')}}</van-button>
        </div>
      </van-form>
    </div>
  </div>
  <!-- <van-popup v-model="showBatch"> -->
    <div v-if="showBatch"  class="card" style="margin-top: 10px;margin-bottom: 20px;">
      <BatchVipOrderSelect v-if="showBatch" 
        :current-deliver="detail"
        :deliverList="deliverList"
        :title="''"
        :subTitle="`同一合同下还有以下${deliverList.length}个交付单可以签署，可操作批量上传`"
        :confirmBtnText="'确认'"
        @confirm="onBatchSelect" 
        @cancel="showBatch = false"/>
  </div>
  <!-- </van-popup> -->
</div>
</template>
<script>
import deliveryServices from '@/services/deliveryServices.js'
import { uploadFile } from '@/utils/cos'
import BasicInfo from './component/basic-info.vue'
import { v4 as uuidv4, validate } from 'uuid'
import loading from '@/utils/loading'
import BatchVipOrderSelect from './component/batch-vip-order-select.vue'

export default {
  name:'deliverRecord',
  components:{ BasicInfo, BatchVipOrderSelect },
  data(){
    return {
      orderId: '',
      taskId: '',
      operation: '',
      detail: {},
      taskDetail: {},
      fileList: [],
      fileUrlArr:[],
      params: {
        id: '',
        taskStatus: '4004003',
        executionJson: {
          taskCode: 'DELIVERY_CONTRACT',
          contractType: 1, // 合影地址
          contractUrl:'', // 交车确认函
        },
      },
      showBatch: false,
      deliverList: [],
    }
  },
  computed:{
    isEdit(){
      const flag = this.operation === 'edit'
      console.log('flag:',flag)
      return flag
    }
  },
  created(){
    const { taskId,orderId,operation } =this.$route.query
    this.taskId = taskId
    this.params.id = taskId
    this.orderId = orderId
    this.operation = operation
    this.getDetail()
    if (operation==='view'){
      this.deliveryTaskDetail()
    }
  },
  methods:{
    // 获取订单详情
    async getDetail() {
      const res = await deliveryServices.deliveryDetail({ id: this.orderId })
      this.detail = res || {}  
      // 大客户,获取批量待申请的订单
      if ([1,2,5].includes(res.orderType)){
        this.getBatchOrder()
      }    
    },
    // 任务详情
    deliveryTaskDetail() {
      deliveryServices.deliveryTaskDetail({
        taskId: this.taskId
      }).then(res=>{
        this.taskDetail = res
        const { contractUrl } = res.executionJson
        this.fileUrlArr = contractUrl.split(',')
        this.fileList = this.fileUrlArr.map(url=>({
          url,
          status:'done',
          uuid: uuidv4(),
          message:''
        }))
      })
    },
    // 图片校验
    beforeRead(file) {
      // const str = file.name.substring(file.name.lastIndexOf('.') + 1)
      // const rules = ['jpg','png','jpeg']
      // if (!rules.includes(str.toLocaleLowerCase())) {
      //   this.$toast.fail(this.$t('请上传正确图片文件(支持jpg/jpeg/png)'))
      //   return false
      // }
      if (!file.type.includes('image')) {
        this.$toast.fail(this.$t('请上传正确图片文件'))
        return false
      }
      return true
    },
    afterRead(file) {
      const uuid = uuidv4()
      const content = file.content
      file.status = 'uploading'
      file.message = this.$t('上传中...')
      file.uuid = uuid
      uploadFile(file.file).then(({ url }) => {
        if (file) {
          file.status = 'done'
          file.message = ''
          file.url = content
          file.cosUrl = url
          this.fileList.push(file)
        }
      }).catch(() => {
        file.status = 'failed'
        file.message = this.$t('上传失败')
      })
    },
    beforeDelete(file) {
      this.fileList = this.fileList.filter(item => item.uuid !== file.uuid)
    },
    goBack(){
      this.$router.go(-1)
    },
    onBatchSelect(selectIds){
      const orderIds = [...selectIds,this.orderId]
      this.$set(this.params,'orderIds',orderIds)
      return false
      // 请求提交
      // this.requestSubmit()
    },
    async getBatchOrder(){
      const res = await deliveryServices.getBatchOrder({ orderId: this.orderId, type:5 }) 
      if (res.length){
        this.deliverList = res
        this.showBatch = true
        return
      }
    },
    async onSubmit(){
      if (!this.fileList.length){
        this.$toast(this.$t('请完成上传'))
        return false
      }

      this.requestSubmit()
    },
    async requestSubmit(){
      this.fileUrlArr = []
      this.fileList.forEach(item=>{
        if (item.status === 'done'){
          this.fileUrlArr.push(item.cosUrl)
        }
      })
      this.params.executionJson.contractUrl = this.fileUrlArr.join(',')
      loading.showLoading()
      const res = await deliveryServices.deliveryExecution(this.params)
      loading.hideLoading()
      if (res.code === 0) {
        setTimeout(() => {
          this.$router.go(-1)
        }, 1000)
      } else {
        this.$toast(res.msg)
      }
    },
    // async onSubmit(){
    //   if (!this.fileList.length){
    //     this.$toast(this.$t('请完成上传'))
    //     return false
    //   }
    //   this.fileUrlArr = []
    //   this.fileList.forEach(item=>{
    //     if (item.status === 'done'){
    //       this.fileUrlArr.push(item.cosUrl)
    //     }
    //   })
    //   this.params.executionJson.contractUrl = this.fileUrlArr.join(',')
    //   loading.showLoading()
    //   const res = await deliveryServices.deliveryExecution(this.params)
    //   loading.hideLoading()
    //   if (res.code === 0) {
    //     setTimeout(() => {
    //       this.$router.go(-1)
    //     }, 1000)
    //   } else {
    //     this.$toast(res.msg)
    //   }
    // },
  }
}
</script>
<style lang="less" scoped>
.deliver-record{
  padding: 16px;

  .card{
    padding:  0 12px 4px;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    .sub-title{
      padding: 12px 0;
    }
  }
  .item{
    margin-bottom: 8px;
    .label{
      height: 16px;
      font-size: 14px;
      font-family: HYQiHei, HYQiHei;
      font-weight: normal;
      color: rgba(13,23,26,0.45);
      line-height: 16px;
    }
    .value{
      height: 16px;
      font-size: 14px;
      font-family: HYQiHei, HYQiHei;
      font-weight: normal;
      color: #0D171A;
      line-height: 16px;
    }
  }

  .record-form{
    /deep/ .van-field{
      display: block;
    }
  }
}
.video-player{
  width: 88px;
  height: 88px;
}
.fullscreen-player{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}
</style>